<template>
    <div>
        <div v-if="authUserPermission['stock-calc-fast-by-manual']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">Stock Calculation by Manual Input</h3>
                            </div>
                            <!-- <div class="col text-right">
                                 <base-button size="sm" type="default" @click="refreshData" :disabled="loadingRefresh">
                                        <span v-if="loadingRefresh"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                        <span v-else>Take Out</span>
                                </base-button>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <label class="form-control-label"><b>Request Demand</b></label> -->
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="align-middle">Request Demand
                                            <el-tooltip content="Demand request dalam 12 bulan untuk simulasi grafik" placement="top">
                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                            </el-tooltip> 
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-1" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[0]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip> 
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-2" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[1]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-3" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[2]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-4" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[3]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-5" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[4]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-6" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[5]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-7" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[6]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-8" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[7]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-9" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[8]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-10" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[9]"  :disabled="loadingCalculate" type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-11" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[10]" :disabled="loadingCalculate"  type="number">
                                            </el-tooltip>
                                        </td>
                                        <td>
                                            <el-tooltip content="Bulan Ke-12" placement="top">
                                                <input class="form-control" placeholder="0" v-model="demandRequest[11]" :disabled="loadingCalculate"  type="number">
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">Lead Time
                                            <el-tooltip content="Jika leadtime bervariasi contohnya 3 bulan, 4.5 bulan maka dapat tambahkan kolom jika leadtime fixed atau konstan maka cukup gunakan satu kolom leadtime" placement="top">
                                                <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                            </el-tooltip> 
                                        </td>
                                        <template id="">
                                            <td v-for="(lead, key) in leadTimes" :key="key+'lead'"><input class="form-control" placeholder="0" v-model="leadTimes[key]"  :disabled="loadingCalculate" type="number"></td>
                                        </template>
                                        <td>
                                            <button class="btn btn-primary mr-1" @click="addLeadTime()">
                                                <el-tooltip content="Jika leadtime bervariasi contohnya 3 bulan, 4.5 bulan maka dapat tambahkan kolom jika leadtime fixed atau konstan maka cukup gunakan satu kolom leadtime" placement="top">
                                                    <i class="fa fa-plus"></i>
                                                </el-tooltip> 
                                            </button>
                                            <button class="btn btn-danger" @click="deleteLeadTime"><i class="fa fa-minus"></i></button>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row mt-3">
                            <div class="col-4">
                                <label class="form-control-label"><b>Initial Inventory</b>
                                    <el-tooltip content="Ketersediaan stok di gudang saat ini, untuk dimulasi grafik" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Initial Inventory" v-model="initialInventory" :disabled="loadingCalculate"  type="number">

                                <!-- <label class="form-control-label"><b>Average Lead Time</b>
                                    <el-tooltip content="Rata-rata durasi leadtime" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Average Lead Time" v-model="avgLeadTime" :disabled="loadingCalculate"  type="number"> -->

                                <!-- <label class="form-control-label mt-3"><b>Lead Time Type</b>
                                    <el-tooltip content="Top center" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <select name="" id="" class="form-control" v-model="leadTimeType" :disabled="loadingCalculate">
                                    <option value="Normal Dist">Normal Dist</option>
                                    <option value="Fixed">Fixed</option>
                                </select> -->

                                <!-- <label class="form-control-label mt-3"><b>Standard Deviation Lead Time</b>
                                        <el-tooltip content="Variasi dari leadtime" placement="top">
                                            <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Standard Deviation Lead Time" v-model="standardDeviationLeadTime" :disabled="loadingCalculate || disabledFixed"  type="number"> -->
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Unit Price</b>
                                       <el-tooltip content="Harga satuan Spare Part" placement="top">
                                            <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Unit Price" v-model="unitPrice" type="text" :disabled="loadingCalculate" @keyup="formatPrice($event, 'unitPrice')">

                                <!-- <label class="form-control-label mt-3"><b>Maximum Lead Time</b>
                                    <el-tooltip content="Durasi terlama leadtime secara historis" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Maximum Lead Time" v-model="maximumLeadTime"  type="number" :disabled="loadingCalculate || disabledFixed"> -->

                                <!-- <label class="form-control-label mt-3"><b>Minimum Lead Time</b>
                                    <el-tooltip content="Durasi tersingkat leadtime secara historis" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Minimum Lead Time" v-model="minimumLeadTime" :disabled="loadingCalculate || disabledFixed"  type="number"> -->
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Metode ROQ</b>
                                    <el-tooltip placement="top">
                                        <div slot="content">1. ROQ dengan parameter biaya: perhitungannya mempertimbangkan biaya penyimpanan (holding cost), biaya pemesanan (ordering cost) <br/> <br>2. ROQ tanpa parameter biaya: perhitungannya tidak mempertimbangkan biaya penyimpanan (holding cost), biaya pemesanan (ordering cost) </div>
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label><br>
                                <el-select v-model="metodeEoq" style="width:100%" placeholder="Choose" :disabled="loadingCalculate || loadingRefresh">
                                    <el-option label="ROQ Dengan Parameter Biaya" value="True"></el-option>
                                    <el-option label="ROQ Tanpa Parameter Biaya" value="False"></el-option>
                                </el-select>
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Order Cost</b>
                                       <el-tooltip content="Biaya pemesanan setiap kali pesan, biaya pemesanan ini dapat mencakup biaya pengiriman, biaya transportasi tak terduga, biaya inspeksi, dan biaya lain yang diperlukan untuk memperoleh produk persediaan." placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                       </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Order Cost" v-model="orderCost" type="text" :disabled="loadingCalculate || disableOrderCost" @keyup="formatPrice($event, 'orderCost')">
                            </div>

                            <div class="col-4">
                                <label class="form-control-label"><b>Annual Carrying Cost (%)</b>
                                    <el-tooltip content="Biaya pengimpanan persediaan/Spare Part, persentase dari unit price" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip> 
                                </label>
                                <input class="form-control" placeholder="Annual Carrying Cost (%)" v-model="annualCarryingCost" :disabled="loadingCalculate || disableAnnual"  type="number">
                            </div>
                            
                            <div class="col-4">
                                <label class="form-control-label"><b>Service Level (%)</b>
                                     <el-tooltip content="Kemampuan memberikan pelayanan kepada user pemeliharaan yang diukur berdasarkan rasio antara tingkat ketersediaan (availability) Material Stock dengan frekuensi permintaan" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip>   
                                </label>
                                <div class="input-group" v-for="(service, key) in serviceLevel" :key="key">
                                    <input class="form-control mb-4" placeholder="Service Level (%)" type="number" v-model="serviceLevel[key]" :disabled="loadingCalculate">
                                    <div class="input-group-btn">
                                        <button class="btn btn-primary mr-1" @click="addServiceLevel(key)" :disabled="loadingCalculate" v-if="key == 0"><i class="fa fa-plus"></i></button>
                                        <button class="btn btn-danger" @click="removeServiceLevel(key)" :disabled="loadingCalculate || key == 0"><i class="fa fa-minus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-center mt-5">
                            <input type="button" class="btn btn-danger" value="Run Calculation" @click="calculate" :disabled="loadingCalculate || disableCalculate || loadingDownload">
                            <base-button  type="default" @click="resetParameter"  :disabled="loadingCalculate || loadingDownload">Reset</base-button>
                        </div>

                        <div v-if="calculationData.length > 0 && !loadingCalculate"> 
                            <div class="table-responsive mt-5" v-if="calculationData.length > 0">
                                <div  v-if="!loadingCalculate">
                                <table class="table table-bordered table-striped">
                                    <thead class="bg-secondary">
                                        <tr>
                                            <th style="font-size:9pt">No</th>
                                            <th style="font-size:9pt">Service Level
                                                    <el-tooltip content="Kemampuan memberikan pelayanan kepada user pemeliharaan yang diukur berdasarkan rasio antara tingkat ketersediaan (availability) Material Stock dengan frekuensi permintaan" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:9pt">Safety Stock
                                                    <el-tooltip content="Persediaan pengaman (buffer stock), yang berguna untuk menjaga kemungkinan terjadinya Stock Out yang diakibatkan karena penggunaan material yang melebihi perkiraan semula atau dikarenakan Lead Time pengiriman material yang lebih lama dari yang diperkirakan" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:9pt">ROP
                                                    <el-tooltip content="Re-Order Point sebagai batas posisi jumlah stock di mana pembelian kembali material harus dilakukan untuk mengisi kembali stock gudang" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:9pt">Min
                                                    <el-tooltip content="Jumlah minimum stock yang harus selalu ada di gudang" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:9pt">Max
                                                    <el-tooltip content="Jumlah maksimum stock yang harus selalu ada di gudang" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:9pt">ROQ
                                                    <el-tooltip content="Reorder Quantity (ROQ) adalah rekomendasi jumlah pesanan dalam sekali pesan" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:9pt">Total Biaya
                                                    <el-tooltip content="Total biaya yang dikeluarkan dari jumlah pesanan (ROQ) dikali harga satuan unit" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <th style="font-size:9pt">Grafik
                                                    <el-tooltip content="Simulasi kondisi stok gudang dari nilai-nilai perhitungan stok selama 12 bulan" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                        </tr>
                                    </thead>
                                    <tbody  v-if="calculations.length != 0">
                                        <tr v-for="(calculate, key) in calculationData" :key="key">
                                            <td style="font-size:11pt">{{ key+1 }}</td>
                                            <td style="font-size:11pt">{{ calculate.Service_Level }}</td>
                                            <td style="font-size:11pt">{{ calculate.Safety_Stock.toFixed() }}</td>
                                            <td style="font-size:11pt">{{ calculate.Reorder_Point }}</td>
                                            <td style="font-size:11pt">{{ calculate.Minimum_Stock }}</td>
                                            <td style="font-size:11pt">{{ calculate.Maximum_Stock }}</td>
                                            <td style="font-size:11pt">{{ calculate.ROQ }}</td>
                                            <td style="font-size:11pt">{{ formatNumber(calculate.Total_Biaya)}}</td>
                                            <td style="font-size:11pt">
                                                <button type="button" class="btn btn-sm btn-primary" @click="displayChart(key)">Detail</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <base-button  type="default" @click="downloadFile"  :disabled="loadingDownload" class="float-right mt-3" size="sm">
                                    <span v-if="loadingDownload"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>Download</span>
                                    <el-tooltip content="Download tabel hasil perhitungan" placement="top">
                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                    </el-tooltip>
                                </base-button>
                                </div>
                                <skeleton-loading v-else/>
                            </div>

                            <div class="mt-5 text-center" v-if="calculationData.length > 0">
                                <h3><b>Summary</b></h3>
                                <div class="py-3" style="border: 1px solid #858585">
                                    <span>Berdasarkan pada hasil perhitungan di atas dapat disimpulkan bahwa stok aman dalam 12 bulan ke depan, <br>jika dengan Service Level <b>{{ summary.Service_Level }}%</b>. Maka disarankan nilai :</span><br>
                                    <span><b>Safety Stock:</b> {{ !!summary ? summary.Safety_Stock.toFixed()  : ""}}, <b>Reorder Point:</b> {{ !!summary ? summary.Reorder_Point  : ""}}, <b>Reorder Quantity(ROQ):</b> {{ !!summary ? summary.ROQ  : ""}}, <b>Minimum:</b> {{ !!summary ? summary.Minimum_Stock  : ""}}, <b>Maximum:</b> {{ !!summary ? summary.Maximum_Stock  : ""}}</span>
                                </div>
                            </div>
                        </div>

                        <skeleton-loading v-else-if="loadingCalculate"></skeleton-loading>

                        <empty v-else></empty>

                        <!-- MODAL SMARTCAT ATTACHMENT -->
                        <modal :show.sync="modalDetail" size="lg">
                            <template slot="header">
                                <h5 class="modal-title">Detail & Grafik Hasil Perhitungan</h5>
                            </template>
                            <template>
                                <canvas id="myChart"></canvas>
                                <div class="table-responsive mt-5">
                                    <table class="table table-bordered customTableKecil">
                                        <tbody>
                                            <tr>
                                                <th>Month</th>
                                                <td>1</td>
                                                <td>2</td>
                                                <td>3</td>
                                                <td>4</td>
                                                <td>5</td>
                                                <td>6</td>
                                                <td>7</td>
                                                <td>8</td>
                                                <td>9</td>
                                                <td>10</td>
                                                <td>11</td>
                                                <td>12</td>
                                            </tr>
                                            <tr>
                                                <th>Available Inventory
                                                    <el-tooltip content="Stok Spare Part yang ada digudang" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <td v-for="available in availableInvetory">{{available}}</td>
                                            </tr>
                                            <tr>
                                                <th>Order Entry
                                                    <el-tooltip content="Kuantiti Spare Part yang diterima" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                <td v-for="entry in orderEntry" :class="{'text-primary fw-bold': (Number(entry) > 0)}">{{entry}}</td>
                                            </tr>
                                            <tr>
                                                <th>Order Issue
                                                    <el-tooltip content="Kuantiti Spare Part yang dipesan" placement="top">
                                                        <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                                    </el-tooltip> 
                                                </th>
                                                 <td v-for="issued in orderIssued" :class="{'text-danger fw-bold': (Number(issued) > 0)}">{{issued}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </modal>
                    </div>
                </div>
            </div>

        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import axios from 'axios'
    import Chart from 'chart.js/dist/chart.js'
    import SkeletonLoading from '../../components/SkeletonLoading.vue';
    import configUrl from '@/configs/config'
import Empty from '../../components/Empty.vue';

    export default {
        components: { SkeletonLoading, Empty },         
        data() {
            return {          
                onLoad: true,      
                loadTimeout: null,            
                demandRequest: [0,0,0,0,0,0,0,0,0,0,0,0],
                initialInventory:50,
                serviceLevel:[90],
                annualCarryingCost:15,
                avgLeadTime:'',
                leadTimeType:'',
                minimumLeadTime:'',
                maximumLeadTime:'',
                unitPrice:'',
                orderCost:'',
                standardDeviationLeadTime:'',
                loadingCalculate:false,
                calculations:[],
                modalDetail:false,
                availableInvetory:[],
                orderEntry:[],
                orderIssued:[],
                loadingRefresh:false,
                summary:{},
                disabledFixed:false,
                canChart:null,
                leadTimes:[0],
                metodeEoq:'', 
                loadingDownload:false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            calculationData(){
                if(this.calculations.length > 0){
                    var tables = []
                    this.calculations.forEach((element) => {
                        var el = Object.keys(element);
                        var data = {};
                        el.forEach((k) => {
                            var nameKey = k.replace(' ','_');
                            data[nameKey] = element[k]
                        })
                        tables.push(data)
                    })
                    let sortData = tables.sort((p1, p2) => (p2.Service_Level > p1.Service_Level) ? 1 : (p2.Service_Level < p1.Service_Level) ? -1 : 0)
                    return sortData;
                }else{
                    return []
                }
            },
            disableCalculate(){
                if(this.initialInventory === '' || this.unitPrice === '' || this.metodeROQ == ''){
                    return true;
                }else{
                    return false;
                }
            },
            disableAnnual(){
                if(this.metodeROQ != '' && this.metodeROQ == "False"){
                    this.annualCarryingCost = ''
                    return true;
                }else{
                    return false;
                }
            },
            disableOrderCost(){
                 if(this.metodeROQ != '' && this.metodeROQ == "False"){
                    this.orderCost = 0
                    return true;
                }else{
                    return false;
                }
            }
        },
        watch:{
            'calculationData': function(val){
                if(val.length > 0){
                    let findData = this.calculationData[0]
                    this.summary = findData;
                }else{
                    this.summary = {}
                }
            },
            'leadTimeType': function(val){
                if(val == 'Fixed'){
                    this.standardDeviationLeadTime = 0;
                    this.minimumLeadTime = 0;
                    this.maximumLeadTime = 0;
                    this.disabledFixed = true;
                }else{
                    this.standardDeviationLeadTime = '';
                    this.minimumLeadTime = '';
                    this.maximumLeadTime = '';
                    this.disabledFixed = false;
                }
            },
        },
        methods: {
            async calculate() {
                this.loadingCalculate = true;
                this.calculations = []
                if(this.serviceLevel.length == 0){
                    this.completeDataNotify('Service Level');
                    return;
                }
                
                this.demandRequest.forEach((element, index) => {
                    this.demandRequest[index] = Number(element)
                })
                 this.serviceLevel.forEach((element, index) => {
                    this.serviceLevel[index] = Number(element)
                })

                this.leadTimes.forEach((element, index) => {
                    this.leadTimes[index] = Number(element)
                })

                var unitPrice = Number(this.unitPrice.replaceAll('.','').replaceAll(',','.'))
                var orderCost = (this.orderCost == 0) ? 0 : Number(this.orderCost.replaceAll('.','').replaceAll(',','.'))
 
                let params = JSON.stringify(
                {
                    // "Lead Time Type":this.leadTimeType,
                    // "Average Lead Time":Number(this.avgLeadTime),
                    // "Standard Deviation Lead Time":Number(this.standardDeviationLeadTime),
                    // "Maximum Lead Time":Number(this.maximumLeadTime),
                    // "Minimum Lead Time":Number(this.minimumLeadTime),
                    "Leadtime" : this.leadTimes,
                    "Service Level":this.serviceLevel,
                    "Initial Inventory":Number(this.initialInventory),
                    "Annual Carrying Cost":Number(this.annualCarryingCost),
                    // "Standard Deviation Lead Time":Number(this.standardDeviationLeadTime),
                    "Unit Price":unitPrice,
                    "Biaya Pemesanan":orderCost,
                    "Request Demand":this.demandRequest,
                    "ROQ dengan Cost": this.metodeEoq
                })

                var config = {
                    method: 'post',
                    url: 'https://api-fm.pupuk-indonesia.com/fastmoving-manual',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : params
                };

                await axios(config).then(response =>  this.calculations =  response.data)
                .catch(error => this.calculateError());
                this.loadingCalculate = false;
            },
            completeDataNotify(title) {
            	this.$notify({
					message: this.tt("please_complete_data", { title: title }),                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            addServiceLevel(){
                if (this.serviceLevel.length == 0) {
                    this.serviceLevel.push('')
                } else if (this.serviceLevel.length > 0) {
                    if (this.serviceLevel[this.serviceLevel.length-1] != '') {
                        this.serviceLevel.push('')
                    } else {
                        this.completeDataNotify('Service Level')
                    }
                }
            },
            removeServiceLevel(key){
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.serviceLevel.splice(key, 1)
			        }
			    });
            },
            displayChart(key) {
                this.modalDetail = true;
                if(this.canChart){
                    this.canChart.destroy()
                }
                const ctx = document.getElementById('myChart');

                const DATA_COUNT = 13;
                const labels = [];
                for (let i = 1; i < DATA_COUNT; ++i) {
                    labels.push(i.toString());
                }
                const datapoints1   = this.calculationData[key].Available_Inventory   
                const datapoints2   = [];
                const datapoints3   = [];
                this.availableInvetory = this.calculationData[key].Available_Inventory;
                this.orderEntry = this.calculationData[key].Order_Entry
                this.orderIssued = this.calculationData[key].Order_Issued
                
                for(var i = 0; i < 12 ; i++){
                    datapoints2.push(Number(this.calculationData[key].Safety_Stock))
                }

                for(var i = 0; i < 12 ; i++){
                    datapoints3.push(Number(this.calculationData[key].Reorder_Point))
                }
                const data = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Availibility Inventory',
                            data: datapoints1,
                            borderColor: 'rgb(255, 0, 0)',
                            fill: false,
                            cubicInterpolationMode: 'monotone',
                            tension: 0.4
                        }, {
                            label: 'Safety Stock',
                            data: datapoints2,
                            borderColor: 'rgb(0, 255, 0)',
                            fill: false,
                            tension: 0.4,
                            pointRadius:0,
                            segment:{
                                borderDash:[6,6]
                            }
                        }, {
                            label: 'Reorder Point',
                            data: datapoints3,
                            borderColor: 'rgb(0, 0, 255)',
                            fill: false,
                            pointRadius:0,
                            segment:{
                                borderDash:[6,6]
                            }
                        }
                    ]
                };
                

                this.canChart = new Chart(ctx, {
                    type: 'line',
                    data: data,
                    options: {
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Order Point Technique'
                            },
                            tooltip:{
                                callbacks:{
                                    title: function(context){
                                        return `Month (${context[0].label})`
                                    }
                                }
                            }
                        },
                        interaction: {
                            intersect: false,
                        },
                        scales: {
                            x: {
                                display: true,
                                title: {
                                    display: true,
                                    text: 'Month',
                                }
                            },
                            y: {
                                display: true,
                                title: {
                                    display: true,
                                    text: 'Unit'
                                },
                                suggestedMin: 0,
                            }
                        }
                    },
                });
            },
            async refreshData() {
                this.loadingRefresh = true;
                var config = {
                    method: 'post',
                    url: 'https://api-fm.pupuk-indonesia.com/refresh-data-source',
                    headers: { },
                };
                await axios(config).then(response => this.$notify({
                        message :response.data["Message"],
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                })).catch(() => {
                    this.$notify({
                        message :'Internal Server Error',
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    })
                });
                this.loadingRefresh = false;
            },
            formatNumber(value) {
                let convert = value.toString();
                let splitValue  = convert.split('.');
                let withDot = splitValue[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                if(!!splitValue[1]){
                    return 'Rp. ' + withDot + ',' + splitValue[1]
                }else{
                    return 'Rp. ' + withDot
                }
            },
            resetParameter(){
                // this.materialNumber = '' 
                this.serviceLevel= [90] 
                this.initialInventory = 50 
                this.annualCarryingCost = 15
                // this.avgLeadTime = ''
                // this.leadTimeType = ''
                // this.minimumLeadTime = ''
                // this.maximumLeadTime = ''
                this.unitPrice = ''
                this.orderCost = this.formatNumber(5000000,'orderCost')
                // this.standardDeviationLeadTime = ''
                this.demandRequest = [0,0,0,0,0,0,0,0,0,0,0,0]
                this.calculations = []
                this.leadTimes = []
            },
            formatPrice(val, variabel) {
                var price = val.target.value.replace(/[^,\d]/g, "").toString()
                price = price.replace(/[^,\d]/g, "").toString();
                var split = price.split(",");
                var lastPrice = split[0].length % 3;
                var formatRupiah = split[0].substr(0, lastPrice);
                var ribuan = split[0].substr(lastPrice).match(/\d{3}/gi);

                if (ribuan) {
                    var separator = lastPrice ? "." : "";
                    formatRupiah += separator + ribuan.join(".");
                }
                formatRupiah = split[1] != undefined ? formatRupiah + "," + split[1] : formatRupiah;
                this[variabel] = formatRupiah
            },
            calculateError(){
                this.$notify({
					message: 'Material tidak ditemukan data leadtime, disarankan menggunakan kalkulator manual input',                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            addLeadTime(){
                if(this.leadTimes.length >= 12){
                    this.$notify({
                        message: 'Maximum Lead Time is 12',                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return;
                }
                this.leadTimes.push("")
            },
            deleteLeadTime(){
                this.leadTimes.splice(-1,1);
            },
            async downloadFile(){
                if(this.calculationData.length > 0){
                    let params = new FormData();
                    params.append('type','Manual');
                    params.append('token',localStorage.token);
                    this.loadingDownload = true
                    for(var i = 0; i < this.calculationData.length; i++){
                        params.append('service_level['+i+']', this.calculationData[i].Service_Level)
                        params.append('safety_stock['+i+']', this.calculationData[i].Safety_Stock.toFixed())
                        params.append('rop['+i+']', this.calculationData[i].Reorder_Point)
                        params.append('min['+i+']', this.calculationData[i].Minimum_Stock)
                        params.append('max['+i+']', this.calculationData[i].Maximum_Stock)
                        params.append('eoq['+i+']', this.calculationData[i].ROQ)
                        params.append('total_biaya['+i+']', this.calculationData[i].Total_Biaya)
                    }

                    var config = {
                        method: 'POST',
                        url: configUrl.apiUrl+'export/stock-calculation',
                        headers: {"Accept": "application/vnd.ms-excel"},
                        responseType: "arraybuffer",
                        data : params,
                    };

                    await axios(config).then((res) => {
                        var FILE = window.URL.createObjectURL(new Blob([res.data]),{type: "application/csv"});
                        
                        var docUrl = document.createElement('a');
                        docUrl.href = FILE;
                        docUrl.setAttribute('download', 'FM-Manual-Input.xlsx');
                        document.body.appendChild(docUrl);
                        docUrl.click();
                    }).catch(error => this.calculateError());

                    this.loadingDownload = false
                    
                }
            }
        }   
    };
</script>

<style scoped>
.el-tooltip__popper {
  max-width: 320px !important;
  word-break: break-word;
}

.customTableKecil tbody tr th, td {
  padding: 6px !important;
  text-align: center;
}
</style>